export const environment = {
    apiBaseUrl: 'https://api.vuframe.io',
    aura3dApiUrl: 'https://api.aura3d.io',
    sanityToken: 'skBRIQNxKsZE18JyPSCu0FqERIrfpFeNwILyL3DJpJZLqmIqF49x1REeYTvBhKPFMDlaRO17FT9NgTLCJ4LXGjoJC9kgFJsychQu7SeuF16xviUCmX58i4ppzIstkzEaXLOELl1nBToCjCGOPKfYaHKPz1YUKkPR6ZP6xfS04kcf8Za7rPvX',
    sanityProjectId: 'p6wgso36',
    ga4TrackingCode: 'G-Z1GW9D5L8E',
    keenProjectId: '5c46f29cc9e77c0001cf1c44',
    keenReadKey: '3843FB113C710106AFECC7E62E03C178DEDA93D50B28247A08D8984B536491F94AB04DF94942A6C7A64320FA4A7AAA6DBB6F34D94980ED565E1BAA5708CFA25C5B70ECFF92F9BFFB60C0461339E517963C4ED2CEF8F886A1C1B4F7532D49CF71',
    version: '0.21.1',
    bugsnagApiKey: '54dbac4d839112de644a3bc85226bea0',
    production: true,
    stage: 'production'
};
